import React, { useEffect, useState } from "react";
import { useApi } from "./../../../../services/helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import AdminLayout from "../../../../components/AdminLayout";
import { Carafe } from "../../../../assets/theme/components/SvgIcons";
import MDButton from "../../../../components/MDButton";
import DriverModal from "./DriverModal";
import MDBox from "../../../../components/MDBox";
import { ROUTES } from "../../../../services/constants";
import moment from "moment/moment";
import { showMessage } from "services/helpers";
import numberToText from "number-to-text/converters/en-us";

export const CUSTOMER_INFO = {
  name: "Customer Name:",
  customer_phone_number: "Customer Phone:",
  address: "Delivery Address:",
  created_at: "Order Date:",
  delivery_date: "Delivery Date:",
  note: "Note:",
};

export const DRIVER_INFO = {
  driver: "Driver Name:",
  phone_number: "Driver Phone:",
  status_name: "Status:",
  tanks_total_cost: "Total Cost:",
  delivery_fee: "Driver Fee:",
};

function OrderCustomDetails() {
  const api = useApi();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openDriverModal, setOpenDriverModal] = useState(false);

  const assignDriver = (driverId) => {
    if (!driverId) {
      showMessage("You must to select a driver in order to perform this action");
      return;
    }
    setLoading(true);
    api.assignDriver(state?.id, { driver: driverId }).handle({
      onSuccess: (result) => {
        setOpenDriverModal(false);
        navigate(ROUTES.ADMIN_ORDERS);
      },
      successMessage: "Driver assigned",
      errorMessage: "Error getting drivers",
      onError: (err) => {
        console.log(err);
      },
      onFinally: () => setLoading(false),
    });
  };

  const getTankTransacctions = (tankTransaction, hideItemType = false) => {
    const itemType = !hideItemType && tankTransaction?.is_exchange ? "Exchange" : "Buy";
    const itemQuantity = tankTransaction?.quantity || 0;
    const itemTank = tankTransaction?.size?.name || tankTransaction?.size || "";

    const itemLabel = `${itemType} ${numberToText.convertToText(itemQuantity, {
      case: "lowerCase",
    })} ${itemTank}`;

    return itemLabel;
  };

  console.log("The state is:", state);
  const orderItemsSubtitle = state?.tank_transactions?.map((trx, index) => (
    <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>{getTankTransacctions(trx)}</Typography>
  ));
  return (
    <AdminLayout title={"Order Management"}>
      <MDBox width={"100%"}>
        <MDBox
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          height={"120px"}
          borderRadius={"10px"}
          mb={5}
          sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <MDBox display={"flex"} alignItems={"center"}>
            <Carafe sx={{ width: 30, ml: 5 }} fontSize={"large"} />
            <MDBox>
              <MDTypography color={"black"} variant={"h3"} ml={2}>
                Order ID: {state?.order_id}
              </MDTypography>
              {/*LLamar a la Api para el tank*/}
              <MDTypography sx={{ fontSize: "16px", fontWeight: 500 }} ml={2}>
                {orderItemsSubtitle}
              </MDTypography>
            </MDBox>
          </MDBox>

          {!state?.driver && (
            <MDButton
              color={"info"}
              sx={{ borderRadius: "20px", mr: 5 }}
              onClick={() => {
                setOpenDriverModal(true);
              }}
              loading={loading}
            >
              Assign Driver
            </MDButton>
          )}
        </MDBox>
        <MDBox display={"flex"} justifyContent={"center"} alignItems={"center"} gap={2}>
          <MDBox
            borderRadius={"10px"}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
            width={"50%"}
            p={3}
          >
            <MDBox borderBottom="1px solid #D9D9D9" mb={2}>
              <MDTypography color={"black"} variant={"body"} ml={1}>
                Customer Information
              </MDTypography>
            </MDBox>
            <Grid container>
              {Object.entries(CUSTOMER_INFO).map(([key, label], index) => {
                // console.log("Key:", key);
                return (
                  <>
                    <Grid item xs={6} md={6} bgcolor={index % 2 ? "#f5f5f5" : "white"}>
                      <MDTypography color={"#364861"}>{label}</MDTypography>
                    </Grid>
                    <Grid item xs={6} md={6} bgcolor={index % 2 ? "#f5f5f5" : "white"}>
                      <MDTypography sx={{ textAlign: "right" }}>
                        {key === "created_at"
                          ? moment(state[key]).format("YYYY-MM-DD")
                          : state[key]}
                      </MDTypography>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </MDBox>
          <MDBox
            borderRadius={"10px"}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
            width={"50%"}
            p={3}
          >
            <MDBox borderBottom="1px solid #D9D9D9" mb={2}>
              <MDTypography color={"black"} variant={"body"} ml={1}>
                Driver Information
              </MDTypography>
            </MDBox>
            <Grid container>
              {Object.entries(DRIVER_INFO).map(([key, label], index) => (
                <>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "#f5f5f5" : "white"}>
                    <MDTypography color={"#364861"}>{label}</MDTypography>
                  </Grid>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "#f5f5f5" : "white"}>
                    {/* Verificar si driver existe antes de acceder a name o phone_number */}
                    {state.driver && (
                      <MDTypography sx={{ textAlign: "right" }}>
                        {key === "driver"
                          ? state.driver.name
                          : key === "phone_number"
                          ? state.driver.phone_number
                          : state[key]}
                      </MDTypography>
                    )}
                    {/* Si driver no existe, mostrar un mensaje o un valor predeterminado */}
                    {!state.driver && (
                      <MDTypography sx={{ textAlign: "right" }}>
                        {key === "driver" ? "Unknown Driver" : state[key]}
                      </MDTypography>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
      <DriverModal
        open={openDriverModal}
        handleClose={() => setOpenDriverModal(false)}
        handleConfirm={assignDriver}
      />
    </AdminLayout>
  );
}

export default OrderCustomDetails;
