import AdminLayout from "../../../components/AdminLayout";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import MDButton from "../../../components/MDButton";
import { FormikProvider, useFormik } from "formik";
import FormikInput from "../../../components/FormikInput";
import { useApi } from "../../../services/helpers";
import { Dialog, Grid } from "@mui/material";
import ZipCodes from "./zipCodes";

export const ManageVariables = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [fee, setFee] = useState({
    driver_fee: "",
    next_day_delivery_fee: "",
    standard_delivery_fee: "",
    order_cancellation_fee: "",
  });
  const [openDialog, setOpenDialog] = useState(false);

  const updateFee = (values) => {
    setLoading(true);
    api.updateFee(values).handle({
      onSuccess: (result) => {
        setFee(values);
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "Fee updated successfully",
      onFinally: () => setLoading(false),
    });
  };

  const validationSchema = Yup.object().shape({
    driver_fee: Yup.string(),
    next_day_delivery_fee: Yup.string(),
    standard_delivery_fee: Yup.string(),
    order_cancellation_fee: Yup.string(),
  });

  const formik = useFormik({
    initialValues: { ...fee },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => updateFee(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    api.getFee().handle({
      onSuccess: (result) => {
        const feeData = result?.data?.results?.[0] || { ...fee };
        setFee(feeData);
      },
      errorMessage: "Sorry, an error occurred",
    });
  }, [api]);

  return (
    <>
      <AdminLayout title={"Management Variables"}>
        <FormikProvider value={formik}>
          <Grid container sx={{ background: "white", borderRadius: 5 }}>
            <Grid item md={6} lg={6} sm={12} padding={2}>
              <MDTypography sx={{ fontSize: "20px", fontWeight: 700, textWrap: "nowrap" }}>
                Driver Fee
              </MDTypography>
              <FormikInput
                name={"driver_fee"}
                type={"number"}
                mb={1}
                errors={formik.errors}
                sx={{ flexGrow: 1 }}
              />
            </Grid>
            <Grid item md={6} lg={6} sm={12} padding={2}>
              <MDTypography sx={{ fontSize: "20px", fontWeight: 700, textWrap: "nowrap" }}>
                Standard delivery fee
              </MDTypography>
              <FormikInput
                name={"standard_delivery_fee"}
                type={"number"}
                mb={1}
                errors={formik.errors}
                sx={{ flexGrow: 1 }}
              />
            </Grid>
            <Grid item md={6} lg={6} sm={12} padding={2}>
              <MDTypography sx={{ fontSize: "20px", fontWeight: 700, textWrap: "nowrap" }}>
                Next day delivery fee
              </MDTypography>
              <FormikInput
                name={"next_day_delivery_fee"}
                type={"number"}
                mb={1}
                errors={formik.errors}
                sx={{ flexGrow: 1 }}
              />
            </Grid>
            <Grid item md={6} lg={6} sm={12} padding={2}>
              <MDTypography sx={{ fontSize: "20px", fontWeight: 700, textWrap: "nowrap" }}>
                Order cancellation fee
              </MDTypography>
              <FormikInput
                name={"order_cancellation_fee"}
                type={"number"}
                mb={1}
                errors={formik.errors}
                sx={{ flexGrow: 1 }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              padding={2}
              alignItems="center"
              justifyContent={"center"}
              display="flex"
            >
              <MDButton
                sx={$button}
                variant="contained"
                color="primary"
                onClick={() => setOpenDialog(true)}
                loading={loading}
              >
                Apply
              </MDButton>
            </Grid>
          </Grid>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <MDBox
              sx={{
                backgroundColor: "#EEEEEE",
                p: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MDTypography>Are you sure you want to update the fee?</MDTypography>
              <MDButton
                sx={{ mt: 2 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  formik.handleSubmit();
                  setOpenDialog(false);
                }}
              >
                Accept
              </MDButton>
            </MDBox>
          </Dialog>
        </FormikProvider>
      </AdminLayout>
    </>
  );
};

const $button = { height: "50px", width: "50%", margin: "auto" };
