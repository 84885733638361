import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Card, createFilterOptions, Grid, TextField } from "@mui/material";
import AdminLayout from "components/AdminLayout";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import MDTypography from "components/MDTypography";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import { useApi } from "services/helpers";
import MDBox from "components/MDBox";
import { CircularProgress } from "@mui/material";
import MDInput from "components/MDInput";

function ZipCodes() {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [zipCodeArr, setZipCodeArr] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    city: Yup.string().required(),
    code: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: { city: "", code: "" },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addZipcodes(values);
    },
    enableReinitialize: true,
  });

  const addZipcodes = (values) => {
    const dataToSend = {
      code: values?.code,
      city: selectedCity,
    };
    console.log("values===", dataToSend);

    setLoading(true);
    api.addZipCode(dataToSend).handle({
      onSuccess: () => {
        getZipCodesApi();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "ZipCode added successfully",
      onFinally: () => setLoading(false),
    });
  };

  const getZipCodesApi = () => {
    api.getZipCodes().handle({
      onSuccess: (result) => {
        setZipCodeArr(result?.response?.reverse());
      },
      onFinally: () => {
        setIsLoading(false);
      },
      errorMessage: "Sorry, an error occurred",
    });
  };

  useEffect(() => {
    setIsLoading(true);
    api.getCities().handle({
      onSuccess: (result) => {
        const StateArray = result?.response?.map((data) => ({
          ...data,
          label: data?.name,
        }));
        setSuggestions(StateArray);
      },
      errorMessage: "Sorry, an error occurred",
    });
    getZipCodesApi();
  }, [api]);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: "start",
    limit: 10,
  });

  return (
    <AdminLayout title={"Zip Codes"}>
      <MDBox
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <FormikProvider value={formik}>
          <Grid container sx={{ background: "white", borderRadius: 5 }}>
            <Grid item md={6} lg={6} sm={12} padding={2}>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <MDTypography sx={{ fontSize: "20px", fontWeight: 700, textWrap: "nowrap" }}>
                  Code{" "}
                </MDTypography>
                <MDTypography
                  sx={{ fontSize: "20px", fontWeight: 700, textWrap: "nowrap", color: "red" }}
                >
                  *
                </MDTypography>
              </Box>
              <FormikInput name={"code"} mb={1} errors={formik.errors} sx={{ flexGrow: 1 }} />
            </Grid>

            <Grid item md={6} lg={6} sm={12} padding={2}>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <MDTypography sx={{ fontSize: "20px", fontWeight: 700, textWrap: "nowrap" }}>
                  City{" "}
                </MDTypography>
                <MDTypography
                  sx={{ fontSize: "20px", fontWeight: 700, textWrap: "nowrap", color: "red" }}
                >
                  *
                </MDTypography>
              </Box>
              <Autocomplete
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    onChange={(text) => {
                      formik.setFieldValue("city", text?.target.value);
                    }}
                    style={{ height: 47, justifyContent: "center" }}
                  />
                )}
                options={suggestions}
                filterOptions={filterOptions}
                onChange={(event, city) => {
                  setSelectedCity(city?.id);
                }}
                value={formik.values.city}
              />
            </Grid>
            <Grid
              item
              sm={12}
              padding={2}
              alignItems="center"
              justifyContent={"center"}
              display="flex"
            >
              <MDButton
                sx={$button}
                variant="contained"
                color="primary"
                onClick={() => formik.handleSubmit()}
                loading={loading}
              >
                Apply
              </MDButton>
            </Grid>
          </Grid>
        </FormikProvider>
        <MDBox
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          mt={4}
          height={"100%"}
        >
          {isLoading ? (
            <MDBox justifyContent={"center"} display={"flex"} alignItems={"center"} flex={1}>
              <CircularProgress />
            </MDBox>
          ) : (
            <>
              {zipCodeArr?.length ? (
                zipCodeArr.map((item) => (
                  <MDBox key={`id-${item?.id}`} width={"100%"}>
                    <Card>
                      <MDBox p={1}>
                        <MDBox display={"flex"} justifyContent={"space-between"} width={"100%"}>
                          <MDBox
                            display={"flex"}
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <MDBox
                              display={"flex"}
                              alignItems={"center"}
                              gap={1}
                              maxWidth={{ xs: "250px", md: "100%" }}
                            >
                              <MDBox
                                display={"flex"}
                                alignItems={"center"}
                                maxWidth={{ xs: "182px", sm: "100%" }}
                                sx={{
                                  flexWrap: { xs: "wrap", sm: "nowrap" },
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <MDBox ml={2}>
                                  <MDTypography
                                    sx={{
                                      fontSize: 15,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontWeight: 600,
                                      marginRight: 1,
                                    }}
                                  >
                                    Code: {item?.code}
                                  </MDTypography>
                                  <MDTypography
                                    sx={{
                                      fontSize: 13,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontWeight: 500,
                                    }}
                                  >
                                    City: {item?.city?.label}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </MDBox>
                ))
              ) : (
                <MDBox
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  minHeight={"50vh"}
                  width={"100%"}
                >
                  <MDTypography variant={"subtitle1"}>No data found</MDTypography>
                </MDBox>
              )}
            </>
          )}
        </MDBox>
      </MDBox>
    </AdminLayout>
  );
}

export default ZipCodes;

const $button = { height: "50px", width: "50%", margin: "auto" };
